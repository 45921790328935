<template>
  <div class="profile-page flex sm-direction-column">
    <nav class="left bg-secondary">
      <div class="profile-info pl-4 pr-4 flex align-center pt-3 pb-1 sm-pl-1 sm-pr-1">
        <img class="round" src="https://via.placeholder.com/66x66?text=adomicilio1" alt="">
        <div class="info pl-1 color-00">
          <h4 class="mb-0 mt-0">Enrique Matzerath</h4>
          <p class="alt">Ingresó con Facebook</p>
          <p class="alt color-20 mb-0">
            <svg class="path-20" width="8" height="10" viewBox="0 0 8 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5"
                    d="M7.705 2.705L5.29 0.29C5.105 0.105 4.85 0 4.585 0H1C0.45 0 0 0.45 0 1V9C0 9.55 0.45 10 1 10H7C7.55 10 8 9.55 8 9V3.415C8 3.15 7.895 2.895 7.705 2.705ZM5 5C5.275 5 5.5 5.225 5.5 5.5V7C5.5 7.275 5.275 7.5 5 7.5H4.5C4.5 7.775 4.275 8 4 8C3.725 8 3.5 7.775 3.5 7.5H3C2.725 7.5 2.5 7.275 2.5 7C2.5 6.725 2.725 6.5 3 6.5H4.5V6H3C2.725 6 2.5 5.775 2.5 5.5V4C2.5 3.725 2.725 3.5 3 3.5H3.5C3.5 3.225 3.725 3 4 3C4.275 3 4.5 3.225 4.5 3.5H5C5.275 3.5 5.5 3.725 5.5 4C5.5 4.275 5.275 4.5 5 4.5H3.5V5H5Z"
                    fill="white"/>
            </svg>

            Puntos Cashback: $20.00
          </p>
        </div>
      </div>
      <ul class="user-menu pl-0 color-00 pb-3 sm-pb-0 sm-mb-0" :class="userMenuActive ? 'menu-active' : ''">
        <button class="btn-clean user-menu-control" @click="toggleUserMenu()"></button>
        <li>
          <router-link class="pl-5 align-center pr-4 pb-2 pt-2 sm-pl-2 sm-pr-1" to="/account/user">
            <span class="align-center flex">
              <svg class="mr-1 path-00" width="20" height="20" viewBox="0 0 20 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 10C12.7625 10 15 7.7625 15 5C15 2.2375 12.7625 0 10 0C7.2375 0 5 2.2375 5 5C5 7.7625 7.2375 10 10 10ZM10 12.5C6.6625 12.5 0 14.175 0 17.5V18.75C0 19.4375 0.5625 20 1.25 20H18.75C19.4375 20 20 19.4375 20 18.75V17.5C20 14.175 13.3375 12.5 10 12.5Z"
                fill="white"/>
              </svg>
              Mis Datos</span>
            <svg class="ml-a path-00" width="8" height="12" viewBox="0 0 8 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
                    fill="white"/>
            </svg>
          </router-link>
        </li>
        <li>
          <router-link class="flex pl-5 align-center pr-4 pb-2 pt-2 sm-pl-2 sm-pr-1" to="/account/cards">
            <span class="align-center flex">
              <svg class="mr-1 path-00" width="22" height="18" viewBox="0 0 22 18" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM19 16H3C2.45 16 2 15.55 2 15V14C2 13.45 2.45 13 3 13H19C19.55 13 20 13.45 20 14V15C20 15.55 19.55 16 19 16Z"
                  fill="white"/>
                </svg>
            Mis Tarjetas</span>
            <svg class="ml-a path-00" width="8" height="12" viewBox="0 0 8 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
                    fill="white"/>
            </svg>
          </router-link>
        </li>
        <li>
          <router-link class="pl-5 align-center pr-4 pb-2 pt-2 sm-pl-2 sm-pr-1" to="/account/addresses">
            <span class="align-center flex">
              <svg class="mr-1 path-00" width="14" height="20" viewBox="0 0 14 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 0C3.13 0 0 3.13 0 7C0 11.17 4.42 16.92 6.24 19.11C6.64 19.59 7.37 19.59 7.77 19.11C9.58 16.92 14 11.17 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
                  fill="white"/>
              </svg>
              Mis Direcciones</span>
            <svg class="ml-a path-00" width="8" height="12" viewBox="0 0 8 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
                    fill="white"/>
            </svg>
          </router-link>
        </li>
        <li>
          <router-link class="pl-5 align-center pr-4 pb-2 pt-2 sm-pl-2 sm-pr-1" to="/account/orders">
            <span class="align-center">
              <svg class="mr-1 path-00" width="16" height="20" viewBox="0 0 16 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 4H12C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6C16 4.9 15.1 4 14 4ZM6 8C6 8.55 5.55 9 5 9C4.45 9 4 8.55 4 8V6H6V8ZM8 2C9.1 2 10 2.9 10 4H6C6 2.9 6.9 2 8 2ZM12 8C12 8.55 11.55 9 11 9C10.45 9 10 8.55 10 8V6H12V8Z"
                  fill="white"/>
              </svg>
              Mis Pedidos</span>
            <svg class="ml-a path-00" width="8" height="12" viewBox="0 0 8 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
                    fill="white"/>
            </svg>
          </router-link>
        </li>
        <li>
          <router-link class="pl-5 align-center pr-4 pb-2 pt-2 sm-pl-2 sm-pr-1" to="/account/cash">
            <span class="align-center flex">
              <svg class="mr-1 path-00" width="16" height="20" viewBox="0 0 16 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.41 5.41L10.58 0.58C10.21 0.21 9.7 0 9.17 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.41ZM10 10C10.55 10 11 10.45 11 11V14C11 14.55 10.55 15 10 15H9C9 15.55 8.55 16 8 16C7.45 16 7 15.55 7 15H6C5.45 15 5 14.55 5 14C5 13.45 5.45 13 6 13H9V12H6C5.45 12 5 11.55 5 11V8C5 7.45 5.45 7 6 7H7C7 6.45 7.45 6 8 6C8.55 6 9 6.45 9 7H10C10.55 7 11 7.45 11 8C11 8.55 10.55 9 10 9H7V10H10Z"
                  fill="white"/>
              </svg>
              Puntos Cashback</span>
            <svg class="ml-a path-00" width="8" height="12" viewBox="0 0 8 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
                    fill="white"/>
            </svg>
          </router-link>
        </li>
        <hr class="bg-color-20 no-sm">
        <li>
          <router-link class="pl-5 align-center pr-4 pb-2 pt-2 sm-pl-2 sm-pr-1" to="/">
            <span>Cerrar sesión</span>
            <svg class="ml-a path-00" width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 2L9 2C9.55 2 10 1.55 10 1C10 0.450001 9.55 -3.93403e-08 9 -8.74228e-08L2 -6.99382e-07C0.900001 -7.95547e-07 1.49493e-06 0.899999 1.39876e-06 2L1.74846e-07 16C7.86805e-08 17.1 0.9 18 2 18L9 18C9.55 18 10 17.55 10 17C10 16.45 9.55 16 9 16L2 16L2 2Z"
                fill="white"/>
              <path
                d="M12.3 5.6925C11.91 5.3025 11.91 4.6825 12.3 4.2925C12.69 3.9025 13.31 3.9025 13.7 4.2925L17.29 7.8925C17.68 8.2825 17.68 8.9125 17.29 9.3025L13.7 12.8925C13.31 13.2825 12.69 13.2825 12.3 12.8925C11.91 12.5025 11.91 11.8825 12.3 11.4925L14.2 9.5925H5C4.45 9.5925 4 9.1425 4 8.5925C4 8.0425 4.45 7.5925 5 7.5925H14.2L12.3 5.6925Z"
                fill="white"/>
            </svg>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="container-padding">
      <router-view name="account"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      userMenuActive: false
    }
  },
  watch: {
    $route (to, from) {
      this.userMenuActive = false
    }
  },
  methods: {
    toggleUserMenu () {
      this.userMenuActive = !this.userMenuActive
    }
  }
}
</script>
<style lang="scss">
.profile-page {
  min-height: 920px;

  .container-padding {
    width: 100%;
    padding-right: 10%;
    position: relative;
    @media (max-width: $sm){
      padding-right: 5%;
    }
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      right: 0;
      z-index: -1;
      background: {
        image: linear-gradient(to bottom, rgba(#FFF, 1) 0%, rgba(0, 0, 0, 0) 10%), url("~@/assets/profile-bg.png");
        position: bottom right;
        size: 100% auto;
        repeat: no-repeat;
      }
    }
  }
}

ul.user-menu {
  min-width: 430px;
  position: relative;
  @media (max-width: $sm) {
    min-width: auto;
  }

  &.menu-active {
    .user-menu-control {
      display: none;
    }

    li {
      a {
        @media (max-width: $sm) {
          display: flex;
        }

        > svg {
          @media (max-width: $sm) {
            display: none
          }
        }
      }
    }
  }

  .user-menu-control {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    @media (max-width: $sm) {
      display: block;
    }
  }

  &.secondary {
    border-top: 1px solid $neutral10;
  }

  li {
    a {
      @include secondaryBgHover;
      display: flex;
      @media (max-width: $sm) {
        display: none;
      }

      > svg {
        @media (max-width: $sm) {
          display: block;
          transform: rotate(90deg);
        }
      }

      &.router-link-active {
        background: $secondaryOn;
        @media (max-width: $sm) {
          display: flex;
        }

        span {
          position: relative;

          &:before {
            position: absolute;
            height: 180%;
            left: -1em;
            background: $neutral00;
            width: 3px;
            content: '';
            border-radius: 2px;
          }
        }
      }
    }
  }
}
</style>
